<template>
  <div>
    <a-space :size="20">
      <span>
        Current Time
        <a-button @click="year -= 1" :disabled="year == thisYear - 2"
          >Last year</a-button
        >
        <span class="mx-30">
          <a-typography-text strong>{{ year }}</a-typography-text>
        </span>
        <a-button @click="year += 1" :disabled="year == thisYear"
          >Next year</a-button
        >
      </span>
    </a-space>

    <div
      class="border d-flex justify-content-between"
      style="width: 1280px; margin: auto"
    >
      <AboutmeChart :year="year + ''" :id="id" />
    </div>
  </div>
</template>

<script>
import AboutmeChart from "./chart/AboutmeChart.vue";
import { useQuery, useResult } from "@vue/apollo-composable";

import { DownOutlined } from "@ant-design/icons-vue";
import { reactive, toRefs, ref, inject } from "vue";
import * as dayjs from "dayjs";
import { useStore } from "vuex";

export default {
  components: {
    AboutmeChart,
    DownOutlined,
  },

  setup() {
    const { $msg, gql, $filters } = inject("$");
    const thisYear = dayjs().format("YYYY");

    const v = reactive({
      year: Number(thisYear),
    });

    const store = useStore();

    const id = store.state.userInfo.id || localStorage.getItem("_gitlabId");

    return {
      ...toRefs(v),
      thisYear,
      id,
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
