<template>
  <div class="content">
    <a-page-header style="padding: 0" title="Chart about user" />
    <Aboutme />
  </div>
</template>

<script>
// @ is an alias to /src
import { useQuery, useResult } from "@vue/apollo-composable";

import Aboutme from "@/components/Aboutme.vue";
export default {
  components: {
    Aboutme,
  },
};
</script>

<style lang="scss" scoped>


</style>
